<script setup lang="ts">
import type { Lang } from 'composables/locale';
const { $i18n } = useNuxtApp();
import { useI18n } from 'vue-i18n';

const route = useRoute();
const { t } = useI18n();

setLocale();
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
$i18n.onBeforeLanguageSwitch = async (oldLocale: Lang): void => (oldLocale);

useHead({
  title: computed(() => t(route.meta.title)),
  meta: [{
    name: 'description', content: t(route.meta.description),
  }],
});
</script>
<template>
  <div>
    <slot />
  </div>
</template>
